import * as React from 'react'
import '!style-loader!css-loader!../src/customStyles.css'
import { I18n } from 'react-redux-i18n';

export default function ImmigrationStatus() {
  return (
    <div>
      <div className="wrapper">
        <h1>{I18n.t('General.titleA1')}</h1>
        <div>
          <h3>{I18n.t('General.titleA2')} </h3>
          <p>
          {I18n.t('General.titleA3')}
          
          </p>
        </div>
        <div>
          <h3>{I18n.t('General.titleA4')}</h3>
          <p>
          {I18n.t('General.titleA5')}
            {' '}
          </p>
        </div>
        <p>
        {I18n.t('General.titleA6')}
          
        </p>
        <div>
          <h5>{I18n.t('General.titleA7')}</h5>
          <p>
          {I18n.t('General.titleA8')}
            
          </p>
          <strong>{I18n.t('General.titleA9')}</strong>
          <ul className="sub">
            <li>
            {I18n.t('General.titleA10')}
            </li>
            <li>
            {I18n.t('General.titleA11')}
            </li>
            <li>
            {I18n.t('General.titleA12')}
              
            </li>
            <li>
            {I18n.t('General.titleA13')}
              
            </li>
            <li>
            {I18n.t('General.titleA14')}
              
            </li>
            <li>
            {I18n.t('General.titleA15')}
              
            </li>
            <li>
            {I18n.t('General.titleA16')}
             
            </li>
          </ul>
          <strong>
          {I18n.t('General.titleA17')}
            
          </strong>
          <ul className="sub">
            <li>
            {I18n.t('General.titleA18')}
              
            </li>
            <li>
            {I18n.t('General.titleA19')}
              
            </li>
            <li>
            {I18n.t('General.titleA20')}
              
            </li>
          </ul>
          <p>
          {I18n.t('General.titleA21')}
            
            <strong>{I18n.t('General.titleA21A')}</strong>
          </p>
          <ul className="sub">
            <li>{I18n.t('General.titleA22')}</li>
            <li>
            {I18n.t('General.titleA23')}
             
            </li>
            <li>
            {I18n.t('General.titleA24')}
              
            </li>
            <li>
            {I18n.t('General.titleA25')}
              
              <ul className="sub">
                <li>{I18n.t('General.titleA26')}</li>
                <li>
                {I18n.t('General.titleA27')}
                </li>
                <li>
                {I18n.t('General.titleA28')}
                  
                </li>
              </ul>
            </li>
            <li>
            {I18n.t('General.titleA29')}
              
            </li>
            <li>
            {I18n.t('General.titleA30')}
              
            </li>
            <li>{I18n.t('General.titleA31')}</li>
          </ul>
        </div>
        <div>
          <p>
          {I18n.t('General.titleA32')}
          </p>
          <p>{I18n.t('General.titleA33')}</p>
          <ul className="sub">
            <li>{I18n.t('General.titleA34')}</li>
            <li>
            {I18n.t('General.titleA35')}
             {' '}
            </li>
            <li>
            {I18n.t('General.titleA36')}
              
            </li>
            <li>
            {I18n.t('General.titleA37')}
              
              <ul>
                <li>{I18n.t('General.titleA38')}</li>
                <li>
                {I18n.t('General.titleA39')}
                  
                </li>
                <li>
                {I18n.t('General.titleA40')}
                  
                </li>
                <li>{I18n.t('General.titleA41')}</li>
                <li>
                {I18n.t('General.titleA42')}
                </li>
                <li>
                {I18n.t('General.titleA43')}
                </li>
                <li>{I18n.t('General.titleA44')}</li>
                <li>
                {I18n.t('General.titleA44A')}
                </li>
              </ul>
            </li>
            <li>
            {I18n.t('General.titleA45')}
              
            </li>
            <li>
            {I18n.t('General.titleA46')}
              
            </li>
            <li>
            {I18n.t('General.titleA47')}
            </li>
            <li>
            {I18n.t('General.titleA48')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
